import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_TENANTS } from './TenantList'; 
import { GET_BILLS } from '../Bills/BillList'; // Ensure this path is correct

// Updated GraphQL mutation with billDescription
const ADD_TENANT = gql`
  mutation AddTenant($name: String!, $email: String!, $phone: String!, $houseno: String!, $billAmount: Float!, $billDescription: String!) {
    addTenant(name: $name, email: $email, phone: $phone, houseno: $houseno, billAmount: $billAmount, billDescription: $billDescription) {
      id
      name
      email
      phone
      houseno
    }
  }
`;

const CreateTenant = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [houseno, setHouseno] = useState('');
    const [billAmount, setBillAmount] = useState('');
    const [billDescription, setBillDescription] = useState('');
    const navigate = useNavigate();

    const [addTenant, { error }] = useMutation(ADD_TENANT, {
        update(cache, { data: { addTenant } }) {
            try {
                const { tenants } = cache.readQuery({ query: GET_TENANTS }) || {};
                cache.writeQuery({
                    query: GET_TENANTS,
                    data: {
                        tenants: tenants ? tenants.concat([addTenant]) : [addTenant],
                    },
                });

                const { bills } = cache.readQuery({ query: GET_BILLS }) || {};
                cache.writeQuery({
                    query: GET_BILLS,
                    data: {
                        bills: bills ? bills.concat([{
                            id: addTenant.id,
                            tenantId: addTenant.id,
                            tenantName: addTenant.name,
                            houseno: addTenant.houseno,
                            amountDue: parseFloat(billAmount),
                            description: billDescription,
                        }]) : [{
                            id: addTenant.id,
                            tenantId: addTenant.id,
                            tenantName: addTenant.name,
                            houseno: addTenant.houseno,
                            amountDue: parseFloat(billAmount),
                            description: billDescription,
                        }],
                    },
                });
            } catch (err) {
                console.error('Error updating cache:', err);
            }
        },
        onError: (error) => {
            console.error('Error creating tenant:', error.message);
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!name || !email || !phone || !houseno || !billAmount || !billDescription) {
                throw new Error('All fields are required.');
            }
            await addTenant({ 
                variables: { 
                    name, 
                    email, 
                    phone, 
                    houseno, 
                    billAmount: parseFloat(billAmount), 
                    billDescription 
                } 
            });
            navigate('/list');
        } catch (error) {
            console.error('Error creating tenant:', error.message);
        }
    };

    const handleBack = () => {
        navigate('/list');
    };

    return (
        <div className="p-4 max-w-lg mx-auto">
            <div className="flex items-center mb-4">
                <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
                    <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
                    <span>Back to Tenants</span>
                </button>
            </div>
            <h2 className="text-2xl font-bold mb-4">Create New Tenant</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="text"
                    placeholder="Houseno"
                    value={houseno}
                    onChange={(e) => setHouseno(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="number"
                    step="0.01"
                    placeholder="Bill Amount"
                    value={billAmount}
                    onChange={(e) => setBillAmount(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <textarea
                    placeholder="Bill Description"
                    value={billDescription}
                    onChange={(e) => setBillDescription(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                ></textarea>
                <button
                    type="submit"
                    className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Create
                </button>
                {error && (
                    <div className="mt-4 text-red-600">
                        Error creating tenant: {error.message}
                    </div>
                )}
            </form>
        </div>
    );
};

export default CreateTenant;
