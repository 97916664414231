import React, { useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import './lTable.css';

const Table = ({ columns, data }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 4 } },
    useGlobalFilter,
    usePagination
  );

  return (
    <div className="table-container">
      {/* Search bar with the same color as table contents */}
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className={`w-full p-2 border text-center text-black  rounded-md ${
            isSearchFocused ? 'border-blue-900' : 'border-blue-500'
          }`}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        />
      </div>

      {/* Table in regular format (desktop view) */}
      <div className="overflow-x-auto hidden sm:block">
        <table
          {...getTableProps()}
          className="responsive-table min-w-full text-sm table-auto border  text-black  sm:rounded-lg md:shadow-md"
        >
          <thead className="">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="p-2 border-b text-black text-center">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="p-2 border-b text-black text-center align-top">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Card-style Layout for Small Screens */}
      <div className="sm:hidden mt-5">
        {page.map((row) => {
          prepareRow(row);
          return (
            <div key={row.id} className="card mb-4 p-4  text-black rounded-lg shadow-md">
              {row.cells.map((cell) => (
                <div key={cell.column.id} className="field mb-2">
                  <strong>{cell.column.Header}:</strong> {cell.render('Cell')}
                </div>
              ))}
            </div>
          );
        })}
      </div>

      {/* Pagination controls centered at the bottom */}
      <div className="pagination mt-4 flex justify-center items-center gap-2">
        <button
          className="pagination-btn text-black p-2"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<< First'}
        </button>{' '}
        <button
          className="pagination-btn text-black p-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'< Prev'}
        </button>{' '}
        {pageOptions.map((page, index) => (
          <button
          key={index}
          onClick={() => gotoPage(index)}
          className={`mx-2 p-2 border rounded text-black ${
            pageIndex === index ? ' text-yellow-500' : ''
          }`}
        >
          {index + 1}
        </button>
        ))}
        <button
          className="pagination-btn text-black p-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'Next >'}
        </button>{' '}
        <button
          className="pagination-btn text-black p-2"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'Last >>'}
        </button>{' '}
      </div>
    </div>
  );
};

export default Table;
