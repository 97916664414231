import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBuilding, FaSun, FaWater } from 'react-icons/fa';

const HomeMaintenance = () => {   
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const apartments = [
    { id: 1, name: 'Arcade Apartments (Demo)', icon: <FaBuilding />, route: '/dash/maintenance' },
    { id: 2, name: 'Sunset Apartments', icon: <FaSun />, route: '' },
    { id: 3, name: 'Riverview Apartments', icon: <FaWater />, route: '' },
  ];

  const handleClick = (route, name) => {
    if (route) {
      navigate(route);
    } else {
      setAlertMessage(`${name} not available for view`);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  const handleReturn = () => {
    navigate('/'); // Navigate back to the main page or homepage
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center">
      <div className="w-full max-w-6xl mb-4">
        <button onClick={handleReturn} className="flex items-center text-gray-600 hover:text-gray-800">
          <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
          <span>Return to Main Page</span>
        </button>
      </div>

      <header className="mb-12 text-center">
        <h1 className="text-4xl font-extrabold text-blue-900">Maintenance Requests</h1>
        <p className="text-gray-600 mt-4 max-w-2xl">
          Select your apartment below to submit or check maintenance requests.
        </p>
      </header>
      
      <section className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-5xl w-full">
        {apartments.map((apartment) => (
          <div
            key={apartment.id}
            className="bg-white rounded-xl shadow-md hover:shadow-lg p-6 flex items-center space-x-4 transition transform hover:-translate-y-1"
            onClick={() => handleClick(apartment.route, apartment.name)}
          >
            <div className="text-blue-900 text-4xl">{apartment.icon}</div>
            <div>
              <h3 className="text-2xl font-semibold text-gray-800">{apartment.name}</h3>
              <p className="text-gray-500">Click to explore maintenance options</p>
            </div>
          </div>
        ))}
      </section>

      {alertMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">{alertMessage}</p>
            <button
              onClick={handleCloseAlert}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeMaintenance;
