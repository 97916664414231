import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

// GraphQL query to get a single event by its ID
const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      date
      time
      about
    }
  }
`;

const ViewEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { id },
  });

  // Display a loading spinner while the query is in progress
  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;

  // Display an error message if there is an error in fetching data
  if (error) return <p>Error: {error.message}</p>;

  // Get the event data from the query result
  const event = data?.getEvent;

  // Navigate back to the events list page
  const handleReturn = () => {
    navigate('/event-list'); 
  };

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center mb-8">
          {/* Return button */}
          <button onClick={handleReturn} className="flex items-center text-gray-600 hover:text-gray-800">
            <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
            <span>Return to Events</span>
          </button>
        </div>
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Event Details</h2>
          {/* Display event details if the event exists */}
          {event ? (
            <div>
              <p><strong>Event Name:</strong> {event.title}</p>
              <p><strong>Event Date:</strong> {new Date(Number(event.date)).toLocaleDateString()}</p>
              <p><strong>Event Time:</strong> {event.time}</p>
              <p><strong>About:</strong> {event.about}</p>
            </div>
          ) : (
            <p>No event found with this ID.</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default ViewEvent;
