import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const ADD_MAINTENANCE_REQUEST = gql`
  mutation AddMaintenanceRequest($title: String!, $description: String!, $status: String!, $priority: String!) {
    addMaintenanceRequest(title: $title, description: $description, status: $status, priority: $priority) {
      id
      title
      description
      status
      priority
    }
  }
`;

const GET_MAINTENANCE_REQUESTS = gql`
  query GetMaintenanceRequests {
    maintenanceRequests {
      id
      title
      description
      status
      priority
    }
  }
`;

const AddMaintenanceRequest = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('Active');
  const [priority, setPriority] = useState('Normal');
  
  const navigate = useNavigate();

  const [addMaintenanceRequest] = useMutation(ADD_MAINTENANCE_REQUEST, {
    update(cache, { data: { addMaintenanceRequest } }) {
      const existingData = cache.readQuery({ query: GET_MAINTENANCE_REQUESTS });
      if (existingData) {
        cache.writeQuery({
          query: GET_MAINTENANCE_REQUESTS,
          data: {
            maintenanceRequests: [...existingData.maintenanceRequests, addMaintenanceRequest],
          },
        });
      }
    },
    onError: (error) => {
      console.error('Error adding maintenance request:', error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addMaintenanceRequest({ variables: { title, description, status, priority } });
      navigate('/maintenance-list'); // Redirect to maintenance-list page
    } catch (error) {
      console.error('Error adding maintenance request:', error.message);
    }
  };

  const handleBack = () => {
    navigate('/maintenance-list');
  };

  return (
    <div className="p-4 max-w-lg mx-auto">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
          <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
          <span>Back to Maintenance List</span>
        </button>
      </div>
      <h2 className="text-2xl font-bold mb-4">Add Maintenance Request</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="Active">Active</option>
          <option value="In-Progress">In-Progress</option>
          <option value="Completed">Completed</option>
        </select>
        <select
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add Request
        </button>
      </form>
    </div>
  );
};

export default AddMaintenanceRequest;
