import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Tenant from '../Tenants/TenantList';
import Bills from '../Bills/BillList';
import EventsList from '../Events/EventsList';
import { FaHome, FaFileAlt, FaDollarSign, FaTools, FaCalendarAlt, FaClock, FaBars, FaTimes, FaChartBar } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Maintenance from '../maintenance/MaintenanceList';
import { useQuery, gql } from '@apollo/client';
import Navbar2 from '../../components/navbar-homeapart';

const GET_EVENTS = gql`
  query {
    getEvents {
      id
      title
      date
      time
      about
    }
  }
`;

const Dashboard = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  
  const [activeSection, setActiveSection] = useState('dashboard');
  
  const { loading, error, data } = useQuery(GET_EVENTS);

  useEffect(() => {
    if (section) {
      setActiveSection(section);
    }
  }, [section]);



  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString('en-US');
  };

 

  return (
    <div className="relative min-h-screen bg-gray-50">
     

     

      

     
        <div className="p-8">
          {activeSection === 'dashboard' && (
            <>
            <Navbar2/>
              

              <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                <h2 className="text-2xl font-semibold mb-4 text-center">Upcoming Events</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {loading ? (
                    <p>Loading events...</p>
                  ) : error ? (
                    <p>Error loading events: {error.message}</p>
                  ) : (
                    data.getEvents.map((event) => (
                      <motion.div
                        key={event.id}
                        className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow hover:shadow-xl transition-all border border-gray-300"
                        whileHover={{ scale: 1.05 }}
                      >
                        <div className="flex items-center mb-3">
                          <h3 className="text-lg font-bold text-gray-800">{event.title}</h3>
                        </div>
                        <div className="text-center">
                          <p className="text-gray-600 mb-1">
                            <FaCalendarAlt className="inline-block text-blue-500 mr-1" />
                            {formatDate(event.date)}
                          </p>
                          <p className="text-gray-600">
                            <FaClock className="inline-block text-gray-500 mr-1" />
                            {event.time}
                          </p>
                        </div>
                      </motion.div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}

          {activeSection === 'tenants' && <Tenant />}
          {activeSection === 'bills' && <Bills />}
          {activeSection === 'maintenance' && <Maintenance />}
          {activeSection === 'events' && <EventsList />}
        </div>
      
    </div>
  );
};

export default Dashboard;
