import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Navbar3 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

 

  return (
    <nav className="flex items-center justify-between px-8 py-4 bg-[#052D56] text-white relative">
      {/* Logo */}
      <div className="text-lg font-bold"><span className="text-blue-800">Nest</span>
      <span className="text-yellow-900">Finder</span></div>

      {/* Hamburger Icon for Small Screens */}
      <div className="md:hidden">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            />
          </svg>
        </button>
      </div>

      {/* Menu Items - Hidden on Small Screens */}
      <ul className="md:flex space-x-8 hidden text-sm">
      
        
        <li>
          <button onClick={() => navigate('/bills')}
           className="hover:text-yellow-400 font-serif">
           Bills
          </button>
        </li>
        <li>
          <button onClick={() => navigate('/maintenance-list')} className="hover:text-yellow-400 font-serif">
            Maintenance
          </button>
        </li>
        <li>
          <button onClick={() => navigate('/event-list')} className="hover:text-yellow-400 font-serif">
            Events
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate('/dash')}
            className="hover:text-yellow-400 font-serif"
          >
           Main
          </button>
        </li>
        
      </ul>

      {/* Action Buttons - Hidden on Small Screens */}
      <div className="md:flex items-center space-x-4 hidden">
        <button onClick={() => {
             
             navigate('/')    
            }}  className="bg-orange-500 px-4 py-2 rounded text-sm font-bold hover:bg-orange">
           Homepage
        </button>
      </div>

      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-[#052D56] text-center md:hidden">
          <ul className="space-y-4 py-4 text-sm">
          
           
            <li>
              <button  onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/bills');
                }} className="hover:text-yellow-400 font-serif">
                Bills
              </button>
            </li>
            <li>
              <button  onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/maintenance-list');
                }} className="hover:text-yellow-400 font-serif">
                Maintenance
              </button>
            </li>
            <li>
              <button onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/event-list');
                }}   className="hover:text-yellow-400 font-serif">
                Events
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/dash');
                }}
                className="hover:text-yellow-400 font-serif"
              >
                Main
              </button>
            </li>
            <li>
              <button   onClick={() => {
                setIsMenuOpen(false);
                navigate('/');
              
              
            }}
               className="bg-orange-500 px-4 py-2 rounded text-sm font-semibold hover:bg-orange">
                 Homepage
              </button>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar3;
