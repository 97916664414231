import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const GET_BILL = gql`
  query GetBill($id: ID!) {
    bill(id: $id) {
      id
      tenantName
      houseno
      amountDue
      description
      
      
    }
  }
`;

const SEND_MAIL = gql`
  mutation SendMail($to: String!, $cc: String, $subject: String!, $text: String!) {
    sendMail(to: $to, cc: $cc, subject: $subject, text: $text)
  }
`;

const ViewBill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_BILL, { variables: { id } });
  const [sendMail] = useMutation(SEND_MAIL);
  const [emailStatus, setEmailStatus] = useState('');
  const [ccEmail, setCcEmail] = useState(''); // State for Cc email

  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;
  if (error) return <p>Error: {error.message}</p>;

  const bill = data?.bill;

  const handleReturn = () => {
    navigate('/bills');
  };

  const handleSendEmail = async () => {
    const subject = `Bill for ${bill.tenantName}`;
    const text = `Dear ${bill.tenantName},\n\nYour bill amount is KSH ${bill.amountDue.toFixed(2)}(${bill.description}).\n\nKind Regards,\n\n Management Team`;

    try {
      await sendMail({ variables: { 
        to: 'nestfind@nestfinderhub.africa', // Replace with actual recipient email
        cc: ccEmail,                 // Include Cc email
        subject,
        text 
      }});
      setEmailStatus('Email sent successfully!');
    } catch (error) {
      console.error(error);
      setEmailStatus('Failed to send email.');
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center mb-8">
          <button onClick={handleReturn} className="flex items-center text-gray-600 hover:text-gray-800">
            <span className="text-xl mr-2">←</span>
            <span>Return to Bills</span>
          </button>
        </div>
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Bill Details</h2>
          {bill && (
            <div>
              <p><strong>Tenant Name:</strong> {bill.tenantName}</p>
              <p><strong>House No:</strong> {bill.houseno}</p>
              <p><strong>Bill Amount:</strong> {bill.amountDue.toFixed(2)} KSH</p>
              <p><strong>Bill info:</strong> {bill.description}</p>
              
              {/* Cc Input Field */}
              <div className="mt-4">
                <label className="block mb-2" htmlFor="ccEmail">Cc:</label>
                <input
                  type="email"
                  id="ccEmail"
                  value={ccEmail}
                  onChange={(e) => setCcEmail(e.target.value)}
                  className="border border-gray-300 rounded-md px-4  space-y-4"
                  placeholder=" additional mail receiver"
                />
              </div>

              <button 
                onClick={handleSendEmail} 
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Send Mail
              </button>
              {emailStatus && <p className="mt-2 text-green-500">{emailStatus}</p>}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default ViewBill;
