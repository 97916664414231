import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Table from '../table/ltable'; // Reusable table component
import { ClipLoader } from 'react-spinners';
import Navbar3 from '../../components/navbar-tenant';

export const GET_TENANTS = gql`
  query {
    tenants {
      id
      name
      email
      phone
      houseno
    }
  }
`;

const TenantList = () => {
  const { loading, error, data } = useQuery(GET_TENANTS);
  const [searchTerm, setSearchTerm] = useState('');

  // Define the columns for the table
  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'House No', accessor: 'houseno' },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div className="flex space-x-2 justify-center">
          <Link to={`/view/${value}`} className="text-blue-600 hover:underline">View</Link>
          <Link to={`/edit/${value}`} className="text-yellow-600 hover:underline">Edit</Link>
          <Link to={`/delete/${value}`} className="text-red-600 hover:underline">Delete</Link>
        </div>
      ),
    },
  ];

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter tenants based on the search term
  const filteredTenants = data?.tenants.filter((tenant) =>
    tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tenant.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tenant.phone.includes(searchTerm) ||
    tenant.houseno.includes(searchTerm)
  ) || [];

  // Loading and error states
  if (loading) return <ClipLoader color="#4A90E2" size={50} />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <Navbar3/>
    <div className="flex flex-col p-6">
    <Link  to="/create">
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Create New Tenant
          </button>
        </Link>
      
      <div className="flex justify-between items-center mb-4">
        
       
        
      </div>
      
      {/* Using the ltable component to render the table */}
      <Table columns={columns} data={filteredTenants} />
    </div>
    </div>
  );
};

export default TenantList;
