import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Table from '../table/ltable'; // Import the reusable table component
import { ClipLoader } from 'react-spinners';
import Navbar5 from '../../components/navbar-maintenance';

export const GET_MAINTENANCE_REQUESTS = gql`
  query GetMaintenanceRequests {
    maintenanceRequests {
      id
      title
      description
      status
      priority
    }
  }
`;

const MaintenanceList = () => {
  const { loading, error, data } = useQuery(GET_MAINTENANCE_REQUESTS);
  const [searchTerm, setSearchTerm] = useState('');

  // Define the columns for the table
  const columns = [
    { Header: 'Title', accessor: 'title' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Priority', accessor: 'priority' },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div className="flex space-x-2 justify-center">
          <Link to={`/view-maintenance/${value}`} className="text-blue-600 hover:underline">View</Link>
          <Link to={`/edit-maintenance/${value}`} className="text-yellow-600 hover:underline">Edit</Link>
          <Link to={`/delete-maintenance/${value}`} className="text-red-600 hover:underline">Delete</Link>
        </div>
      ),
    },
  ];

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter maintenance requests based on the search term
  const filteredRequests = data?.maintenanceRequests.filter((request) =>
    request.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    request.description.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Loading and error states
  if (loading) return <ClipLoader color="#4A90E2" size={50} />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <Navbar5/>
    <div className="flex flex-col p-6">
      <div className="flex justify-center items-center mb-4">
        
        <Link to="/add-maintenance">
          <button  className="px-4 py-2 text bg-blue-500 text-white rounded hover:bg-blue-600">
            Create New Maintenance Request
          </button>
        </Link>
      </div>
      
      {/* Using the ltable component to render the table */}
      <Table columns={columns} data={filteredRequests} />
    </div>
    </div>
  );
};

export default MaintenanceList;
