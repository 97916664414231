import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Table from '../table/ltable'; // Import the reusable table component
import { ClipLoader } from 'react-spinners';
import Navbar4 from '../../components/navbar-bills';

export const GET_BILLS = gql`
  query {
    bills {
      id
      tenantName
      houseno
      amountDue
    }
  }
`;

const BillsList = () => {
  const { loading, error, data } = useQuery(GET_BILLS);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const filteredBills = data?.bills.filter((bill) =>
    bill.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bill.houseno.includes(searchTerm)
  ) || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBills = filteredBills.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredBills.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;
  if (error) return <p>Error: {error.message}</p>;

  // Define columns for the table
  const columns = [
    { Header: 'Tenant Name', accessor: 'tenantName' },
    { Header: 'House No', accessor: 'houseno' },
    { Header: 'Bill Amount (KSH)', accessor: 'amountDue', Cell: ({ value }) => `${value.toFixed(2)} KSH` },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div className="flex space-x-2 justify-center">
          <Link to={`/viewbill/${value}`} className="text-blue-600 hover:underline">View</Link>
          <Link to={`/editbill/${value}`} className="text-yellow-600 hover:underline">Edit</Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Navbar4/>
    <div className="flex flex-col p-6">
     
      <div className="mb-4 text-center">
        
      </div>

      <section className="bg-white p-6 rounded-lg shadow-md">
       

        {/* Use ltable to render the table */}
        <Table columns={columns} data={currentBills} />

        
        
      </section>
    </div>
    </div>
  );
};

export default BillsList;
