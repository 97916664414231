import React, { useState } from 'react'; // Importing React and useState
import { gql, useMutation } from '@apollo/client'; // Importing gql and useMutation from Apollo Client
import { useNavigate } from 'react-router-dom'; // Importing useNavigate from react-router-dom

// Define the GraphQL mutation for adding an event
const ADD_EVENT = gql`
  mutation AddEvent($title: String!, $date: String!, $time: String!, $about: String!) {
    addEvent(title: $title, date: $date, time: $time, about: $about) {
      id
      title
      date
      time
      about
    }
  }
`;

// CreateEvent component
const CreateEvent = () => {
  // Define state variables
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [about, setAbout] = useState('');
  const navigate = useNavigate(); // Initialize navigate for routing
  const [addEvent, { error: mutationError }] = useMutation(ADD_EVENT); // Use the mutation

  const handleBack = () => {
    navigate('/event-list');
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      // Send the mutation with the input values
      await addEvent({
        variables: { title, date, time, about },
      });
      navigate('/event-list'); // Redirect to the event list after successful mutation
    } catch (err) {
      console.error('Error adding event:', err);
    }
  };

  return (
    <div className="p-4 max-w-lg mx-auto bg-gray-100 min-h-screen">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
          <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
          <span>Back to Event List</span>
        </button>
      </div>

      <h2 className="text-2xl font-bold mb-4">Create Event</h2>

      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-md">
        <div>
          <label htmlFor="title" className="block text-sm font-semibold text-gray-700">Event Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            required
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="date" className="block text-sm font-semibold text-gray-700">Date</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="time" className="block text-sm font-semibold text-gray-700">Time</label>
          <input
            type="time"
            id="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="about" className="block text-sm font-semibold text-gray-700">About</label>
          <input
            type="text"
            id="about"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            placeholder="Description"
            required
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
        >
          Add Event
        </button>

        {mutationError && <p className="text-red-600 mt-2 text-sm">Error: {mutationError.message}</p>}
      </form>
    </div>
  );
};

export default CreateEvent;
