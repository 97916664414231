import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { GET_TENANTS } from './TenantList'; 
import { GET_BILLS } from '../Bills/BillList'; 

const DELETE_TENANT = gql`
    mutation DeleteTenant($id: ID!) {
        deleteTenant(id: $id)
    }
`;

const DeleteTenant = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [deleteTenant] = useMutation(DELETE_TENANT, {
        update(cache) {
            try {
                // Update tenants cache
                const { tenants } = cache.readQuery({ query: GET_TENANTS }) || { tenants: [] };
                cache.writeQuery({
                    query: GET_TENANTS,
                    data: {
                        tenants: tenants.filter(tenant => tenant.id !== id),
                    },
                });

                // Update bills cache
                const { bills } = cache.readQuery({ query: GET_BILLS }) || { bills: [] };
                cache.writeQuery({
                    query: GET_BILLS,
                    data: {
                        bills: bills.filter(bill => bill.tenantId !== id),
                    },
                });
            } catch (error) {
                console.error('Error updating cache:', error.message);
            }
        },
        refetchQueries: [{ query: GET_TENANTS }, { query: GET_BILLS }],
        onCompleted: () => {
            console.log('Delete successful');
            navigate('/list');
        },
        onError: (error) => {
            console.error('Error deleting tenant:', error.message);
        }
    });

    const handleDelete = () => {
        
            deleteTenant({ variables: { id } });
        
    };

    return (
        <div className="p-4 max-w-lg mx-auto text-center">
            <h2 className="text-2xl font-bold mb-4">Delete Tenant</h2>
            <p className="mb-4">Are you sure you want to delete this tenant?</p>
            <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
                Delete
            </button>
            <button
                onClick={() => navigate('/list')}
                className="ml-4 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
                Cancel
            </button>
        </div>
    );
};

export default DeleteTenant;
