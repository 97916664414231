import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';

const GET_MAINTENANCE_REQUEST = gql`
  query GetMaintenanceRequest($id: ID!) {
    maintenanceRequest(id: $id) {
      id
      title
      description
      status
      priority
    }
  }
`;

const ViewMaintenance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_MAINTENANCE_REQUEST, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const maintenanceRequest = data?.maintenanceRequest;

  const handleReturn = () => {
    navigate('/maintenance-list'); // Navigate to /dash under maintenance
  };

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center mb-8">
          {/* Return button using Unicode arrow */}
          <button onClick={handleReturn} className="flex items-center text-gray-600 hover:text-gray-800">
            <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
            <span>Return to Maintenance</span>
          </button>
        </div>
        <h1 className="text-3xl font-semibold text-gray-800 mb-8">Maintenance Request Details</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p><strong>Title:</strong> {maintenanceRequest.title}</p>
          <p><strong>Description:</strong> {maintenanceRequest.description}</p>
          <p><strong>Status:</strong> {maintenanceRequest.status}</p>
          <p><strong>Priority:</strong> {maintenanceRequest.priority}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewMaintenance;
