import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Content = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const features = [
    
    {
      icon: '🏡',
      title: 'Resident Management',
      description: 'Handle tenant information and interactions.',
      link: '/apartments', // Link to the apartments list page
    },
    {
      icon: '🛠️',
      title: 'Maintenance',
      description: 'Efficiently manage repair and service requests.',
      link:'/home-maintenance'
    },
    {
      icon: '💼',
      title: 'Leasing & Payments',
      description: 'Streamline rent collection and lease management.',
    },
    {
      icon: '🚗',
      title: 'Parking Solutions',
      description: 'Manage vehicle entries and parking spots efficiently.',
    },
    {
      icon: '💻',
      title: 'Financial Tracking',
      description: 'Keep track of all your accounts with ease.',
    },
    {
      icon: '📊',
      title: 'Billing Solutions',
      description: 'Automate billing and invoicing for hassle-free payments.',
    },
    {
      icon: '📑',
      title: 'Expense Tracking',
      description: 'Monitor and control all your property expenses.',
    },
    {
      icon: '📈',
      title: 'Analytics & Reports',
      description: 'Generate detailed reports for insightful decisions.',
    },
    
  ];

  const handleClick = (title, link) => {
    if (link) {
      navigate(link); // Navigate to the specified link
    } else {
      setModalMessage(`${title} will be available soon.`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-white py-12">
      <div id="content" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-900 font-semibold tracking-wide uppercase">Our Services</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What We Offer
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Explore a wide range of features designed to help you manage your property with ease.
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex items-center cursor-pointer hover:bg-gray-100 p-4 rounded"
                onClick={() => handleClick(feature.title, feature.link)}
              >
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-900 text-white">
                    <span className="text-2xl">{feature.icon}</span>
                  </div>
                </div>
                <div className="ml-4 flex-grow">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 hover:underline">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded shadow-lg">
              <h3 className="text-lg font-medium text-gray-900">{modalMessage}</h3>
              <button
                onClick={handleCloseModal}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
