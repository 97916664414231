// src/App.js
import './App.css';
import Home from './pages/Homepage/home';

import TenantList from './pages/Tenants/TenantList';
import UpdateTenant from './pages/Tenants/UpdateTenant';

import { Route, Routes } from 'react-router-dom';
import ViewTenant from './pages/Tenants/ViewTenant';
import client from './apollo-client';
import { ApolloProvider } from '@apollo/client';
import ApartmentsList from './pages/Apartments/apartmentlist';

import Dashboard from './pages/Apartments/Dashboard';
import DeleteTenant from './pages/Tenants/DeleteTenant';
import CreateTenant from './pages/Tenants/CreateTenant';
import BillsList from './pages/Bills/BillList';
import ViewBill from './pages/Bills/ViewBill';
import EditBill from './pages/Bills/EditBill';
import AddMaintenanceRequest from './pages/maintenance/AddMaintenanceRequest';
import MaintenanceList from './pages/maintenance/MaintenanceList';
import EditMaintenanceRequest from './pages/maintenance/EditMaintenanceRequest';
import DeleteMaintenanceRequest from './pages/maintenance/DeleteMaintenanceRequest';
import ViewMaintenance from './pages/maintenance/ViewMaintenace';
import CreateEvent from './pages/Events/CreateEvent';
import DeleteEvent from './pages/Events/DeleteEvent';
import EventsList from './pages/Events/EventsList';
import ViewEvent from './pages/Events/ViewEvent';
import HomeMaintenance from './pages/maintenance/homemaintenance';




function App() {
  return (
      <ApolloProvider client={client}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/view/:id" element={<ViewTenant />} />
            <Route path="/list" element={<TenantList />} />
            <Route path="/edit/:id" element={<UpdateTenant />} />
            <Route path="/delete/:id" element={<DeleteTenant />} />
            <Route path="/create" element={<CreateTenant/>} />
            <Route path="/add-maintenance" element={<AddMaintenanceRequest/>} />
            <Route path="/maintenance-list" element={<MaintenanceList/>} />
            <Route path="/edit-maintenance/:id" element={<EditMaintenanceRequest />} />
            <Route path="/delete-maintenance/:id" element={<DeleteMaintenanceRequest />} />
            <Route path="/view-maintenance/:id" element={<ViewMaintenance/>} />
            <Route path="/create-event" element={<CreateEvent />} />
            
            <Route path="/delete-event/:id" element={<DeleteEvent />} />
            <Route path="/event-list" element={<EventsList />} />



            
            <Route path="/apartments" element={<ApartmentsList />} />
            <Route path="/home-maintenance" element={<HomeMaintenance />} />
           
            <Route path="/bills" element={<BillsList />} />
            <Route exact path="/dash" element={<Dashboard />} />
            <Route path="/dash/:section" element={<Dashboard />} />
            <Route path="/editbill/:id" element={<EditBill />} />
            <Route path="/viewbill/:id" element={<ViewBill/>} />
            <Route path="/view-event/:id" element={<ViewEvent/>} />

          </Routes>
        </div>
      </ApolloProvider>
  );
}

export default App;
