import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { GET_TENANTS } from './TenantList'; // Ensure this path is correct
import { GET_BILLS } from '../Bills/BillList'; // Correct import path

const GET_TENANT = gql`
    query GetTenant($id: ID!) {
        tenant(id: $id) {
            id
            name
            email
            phone
            houseno
        }
    }
`;

const UPDATE_TENANT = gql`
    mutation UpdateTenant($id: ID!, $name: String!, $email: String!, $phone: String, $houseno: String) {
        updateTenant(id: $id, name: $name, email: $email, phone: $phone, houseno: $houseno) {
            id
            name
            email
            phone
            houseno
        }
    }
`;

const UpdateTenant = () => {
    const { id } = useParams();
    const { data, loading, error } = useQuery(GET_TENANT, { variables: { id } });
    const [updateTenant] = useMutation(UPDATE_TENANT, {
        update(cache, { data: { updateTenant } }) {
            // Update the single tenant cache
            cache.writeQuery({
                query: GET_TENANT,
                variables: { id },
                data: { tenant: updateTenant },
            });

            // Update the list of tenants cache
            const { tenants } = cache.readQuery({ query: GET_TENANTS });
            cache.writeQuery({
                query: GET_TENANTS,
                data: {
                    tenants: tenants.map(tenant =>
                        tenant.id === id ? updateTenant : tenant
                    ),
                },
            });

            // Update the bills cache
            const { bills } = cache.readQuery({ query: GET_BILLS });
            cache.writeQuery({
                query: GET_BILLS,
                data: {
                    bills: bills.map(bill =>
                        bill.tenantId === id
                            ? { ...bill, tenantName: updateTenant.name, houseno: updateTenant.houseno }
                            : bill
                    ),
                },
            });
        },
        refetchQueries: [{ query: GET_BILLS }], // Refetch BillList after mutation
        onError: (error) => {
            console.error('Error updating tenant:', error.message);
        },
    });

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [houseno, setHouseno] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setName(data.tenant.name);
            setEmail(data.tenant.email);
            setPhone(data.tenant.phone);
            setHouseno(data.tenant.houseno);
        }
    }, [data]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await updateTenant({ variables: { id, name, email, phone, houseno } });
            navigate('/list');
        } catch (error) {
            console.error('Error updating tenant:', error.message);
        }
    };

    const handleBack = () => {
        navigate('/list');
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">Error: {error.message}</p>;

    return (
        <div className="p-4 max-w-lg mx-auto">
            <div className="flex items-center mb-4">
                <button onClick={handleBack} className="flex items-center text-gray-600 hover:text-gray-800">
                    <span className="text-xl mr-2">←</span> {/* Unicode left arrow */}
                    <span>Back to Tenants</span>
                </button>
            </div>
            <h2 className="text-2xl font-bold mb-4">Update Tenant</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Houseno"
                    value={houseno}
                    onChange={(e) => setHouseno(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <button
                    type="submit"
                    className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Update
                </button>
            </form>
        </div>
    );
};

export default UpdateTenant;
